import { get, post } from "../utils/http"

function restaurantBooking(data) {
    const url = "/v1/restaurant-booking/request";
    return post({ url , data});
}

function cancelBooking(bookingId) {
    const url = "/v1/restaurant-booking/cancel/" + bookingId;
    return post({ url});
}

function getRestaurantBookingRequests() {
    const url = "/v1/restaurant-booking/all-requests";
    return get({url})
}

function getActiveRestaurantBookingRequests() {
    const url = "/v1/restaurant-booking/active-requests";
    return get({url})
}

function getAvaliableBookingTimes(restaurantId) {
    const url = "/v1/restaurant-booking/avaliable-times/" + restaurantId;
    return get({url})
}

function cancelRestaurantBooking(requestId) {
    const url = "/v1/restaurant-booking/cancel/" + requestId;
    return post({url})
}

function getRestaurantBookingDetails(requestId){
    const url = "/v1/restaurant-booking/details/" + requestId;
    return get({url})
}

function getRestaurantBookingDetailsByThirdPartyOrderId(orderId){
    const url = "/v1/restaurant-booking/order/" + orderId;
    return get({url})
}

export default {
    restaurantBooking,
    cancelBooking,
    getRestaurantBookingRequests,
    getActiveRestaurantBookingRequests,
    cancelRestaurantBooking,
    getRestaurantBookingDetails,
    getRestaurantBookingDetailsByThirdPartyOrderId,
    getAvaliableBookingTimes
}