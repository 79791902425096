import { Toast } from 'vant';

export function loading() {
    Toast.loading({
        duration: 0,
        overlay: true,
        loadingType: 'spinner',
    })
}

export function clearLoading() {
    Toast.clear();
}