<template>
  <div class="index my-booking">
    <!-- <van-nav-bar :title="'预定详情'" left-arrow @click-left="onBack" /> -->
  <van-skeleton title :row="6" :loading="loading">
    <div class="booking-detail-card">
      <h3>{{getStatus(booking)}}</h3>
      <div class="content">
        <div class="ho-center left-right">
          <div class="restaurant-info ho-center">
            <van-image :src="booking.restaurantImageUrl" />
            <!-- <img src="../assets/banner1.png" alt=""> -->
            <span>{{booking.restaurantName}}</span>
          </div>
          <div>
            <!-- <van-icon color="#999" name="arrow" @click="onRestaurant(booking.restaurantId)" /> -->
          </div>
          
        </div>
        <van-cell title="预定" :value="booking.personName" />
        <van-cell title="预定电话" :value="booking.telphone" />
        <van-cell title="预定人数" :value="booking.personNumber > 8 ? '8+人' : booking.personNumber + '人'" />
        <van-cell title="预定日期" :value="getBookingTime(booking)" />
        <van-cell title="过敏源" :value="booking.allergy" />
        <van-cell title="备注" :value="booking.remark" />
      </div>
      <hr>
      <div class="float-right ho-center" >
        <van-button v-if="isDoCanceling(booking)" plain size="mini" type="" @click="onCancel(booking.id)">取消预定</van-button>
      </div>
    </div>
  </van-skeleton>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted } from "vue";
import { useRouter , useRoute } from "vue-router";
import { parseTime } from "../utils/index"
import { loading , clearLoading} from "../utils/loading";
import BookingService from "../api/restaurant-booking-service";
import "../css/my-bookings.scss";

export default {
  components: {},
  setup() {
    const state = reactive({
      booking: {},
      loading: false,
    });
    const router = useRouter();
    const {id} = useRoute().params;
    const onBack = () => router.back();
    const onRestaurant = (id) => router.push("/restaurant/" + id);
    const onCancel = (id) => {
        loading();
        BookingService.cancelBooking(id).then(() => onLoadBooking(id))
          .then(clearLoading , clearLoading);
    } ;
    const onLoadBooking = (id) => BookingService.getRestaurantBookingDetails(id)
        .then(booking => state.booking = booking)
        
    const getStatus = booking => {
      if( booking.status == 'USER_BOOKING') {
        return "申请预约"
      } else if( booking.status == 'USER_CANCEL') {
        return "已取消预约"
      } else if( booking.status == 'RESTAURANT_CONFIRM') {
        return "已确定预约"
      } else if( booking.status == 'RESTAURANT_REJECT') {
        return "已拒绝预约"
      } else if( booking.status == 'RESTAURANT_CANCEL') {
        return "已取消预约"
      } else {
        return "";
      }
    }
    const isDoCanceling = (booking) => booking.status == 'USER_BOOKING' ||  booking.status == 'RESTAURANT_CONFIRM';
    const getBookingTime = booking =>  parseTime(new Date(booking.bookingTime), "{y}-{m}-{d} {h}:{i}");
    onMounted(() => {
      loading();
      onLoadBooking(id).then(clearLoading, clearLoading);
    });
    return {
      ...toRefs(state),
      onBack,
      onCancel,
      isDoCanceling,
      onRestaurant,
      getStatus,
      getBookingTime
    };
  },
};
</script>
